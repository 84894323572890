<!-- <div *ngIf="CONFIG.title !== 'Nesma'">
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5">
                <div *ngFor="let category of allCategories; let i = index;">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6>
                </div>
            </div>
            <div class="col-7">
                <img src="assets/images/bestgifts/category1.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName1)">
                <img src="assets/images/bestgifts/category2.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)">
            </div>
            
        </div>
    </div>
</div> -->



<style>
    p{
        font-size: 2vw;
    }
</style>
<div *ngIf="category==='fruitsres'">
    
                <div *ngFor="let category of allCategories; let i = index;">
                    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productListing(category.name)"><p style="font-size: 4vw!important;">{{category.name}}</p></a>
                    <!-- <h6 style="color: white!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6> -->
                </div>
           
</div>
<div *ngIf="category==='vegetablesres'">
    
    <div *ngFor="let category of allCategories1; let i = index;">
        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productListing(category.name)"><p>{{category.name}}</p></a>
        <!-- <h6 style="color: white!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6> -->
    </div>

</div>
<div *ngIf="category==='othersres'" style="column-count: 3;">
    
    <div *ngFor="let category of allCategories2; let i = index;">
        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productListing(category.name)"><p>{{category.name}}</p></a>
        <!-- <h6 style="color: white!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6> -->
    </div>

</div>
<div *ngIf="category==='fruits' && CONFIG.title !== 'Cle' && CONFIG.title !== 'LinenRaj' && CONFIG.title !== 'Schift Cosmetics' && CONFIG.title !== 'LushNGreen' && CONFIG.title !== 'KatbookStore' && CONFIG.title !== 'SriSenbaga'&& CONFIG.title !== 'BestGifts'">
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of allCategories; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
            <div class="col-7"  *ngIf="CONFIG.title !== 'Cle' && CONFIG.title !== 'LinenRaj' && CONFIG.title !== 'Schift Cosmetics' && CONFIG.title !== 'LushNGreen' && CONFIG.title !== 'KatbookStore'">
                <ul style="column-count:2;display: block;">


                    <li *ngFor="let category of allCategories; let i = index;">

                        <img [title]=category.name [src]=category.category_icon [alt]=category.name style="height:100px;margin-bottom: 10px;cursor: pointer;width: 120px;" (click)="productListing(category.name)">
                    </li>
                </ul>
                <!-- <p class="close" *ngIf="appService.currentUrl !== 'checkout'" (click)="removeItem(i)">&#x292C;</p> -->
                <!-- <img [src]="categoryImage1" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName1)"> -->
                <!-- <img [src]="categoryImage2" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
                     <!-- <img src="assets/images/bestgifts/category2.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='fruits' && CONFIG.title === 'BestGifts'">
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of allCategories; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productsListTazTest1(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
            <div class="col-7">
                <ul style="column-count:2;display: block;">


                    <li *ngFor="let category of allCategories; let i = index;">

                        <img [title]=category.name [src]=category.category_icon [alt]=category.name style="height:100px;margin-bottom: 10px;cursor: pointer;width: 120px;" (click)="productsListTazTest1(category.name)">
                    </li>
                </ul>
                <!-- <p class="close" *ngIf="appService.currentUrl !== 'checkout'" (click)="removeItem(i)">&#x292C;</p> -->
                <!-- <img [src]="categoryImage1" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName1)"> -->
                <!-- <img [src]="categoryImage2" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
                     <!-- <img src="assets/images/bestgifts/category2.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='fruits' && CONFIG.title === 'Cle' || CONFIG.title === 'LinenRaj'">
    <div class="min-cart-product-wrapper popup-content1 cle-menu1 avonHome1" style="right: -20%!important;">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of allCategories; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productsListTazTest1(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
    <div class="min-cart-product-wrapper popup-content1 cle-menu2 navBar2" style="right: -20%!important;">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of allCategories; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productsListTazTest1(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
    <div class="min-cart-product-wrapper popup-content-cle avonHome5" style="width: 155%!important;">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of allCategories; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productsListTazTest1(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
    <div class="min-cart-product-wrapper popup-content-cle cle-menu2 avonHome3" style="right: -20%!important;">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of allCategories; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productsListTazTest1(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='fruits' && CONFIG.title === 'Schift Cosmetics'">
    <!-- <div class="min-cart-product-wrapper popup-content1 cle-menu1 avonHome1" style="top: 60% !important;width: auto !important;min-width: 1% !important;"> -->
        <!-- <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a href="javascript:;" class="nav-link menu-text" style="width: max-content;" href="#/about-us" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">ABOUT US</p></a>
                <a href="#/contact-us" class="nav-link menu-text" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">CONTACT US</p></a>
            </div>
            
        </div> -->
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    <!-- </div> -->
    <div class="min-cart-product-wrapper popup-content1 cle-menu2 navBar2" style="top: 60% !important;right: 5%;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a href="javascript:;" class="nav-link menu-text"href="/about-us" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">ABOUT US</p></a>
                <a href="/contact-us" class="nav-link menu-text" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">CONTACT US</p></a>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
    <div class="min-cart-product-wrapper popup-content1 cle-menu2 avonHome3" style="top: 60% !important;right: 5%;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a href="javascript:;" class="nav-link menu-text"href="/about-us" style="width: max-content;" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">ABOUT US</p></a>
                <a href="/contact-us" class="nav-link menu-text" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">CONTACT US</p></a>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='othermenus' && CONFIG.title === 'LushNGreen'">
    <div class="min-cart-product-wrapper popup-content-Taz avonHome5" style="top: 75%; width: auto !important;min-width: 1% !important;">
        <div class="cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu" style="display: block;cursor: pointer;">
                <a class="nav-link menu-text" style="width: max-content;" (click)="productsListTazTest1('Decor')"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">GARDEN DECOR</p></a>
                <a class="nav-link menu-text" style="width: max-content;" (click)="productsListTazTest1('Bouquet')"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">RETURN GIFTS</p></a>
            </div>
        </div>
    </div>
    </div>
<div *ngIf="category==='fruits' && CONFIG.title === 'SriSenbaga'">
    <div class="min-cart-product-wrapper popup-content1 cle-menu2 avonHome3" style="top: 75% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu" style="display: block;">
                <a href="javascript:;" class="nav-link menu-text"href="/about-us" style="width: max-content;" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">ABOUT US</p></a>
                <a href="/contact-us" class="nav-link menu-text" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">CONTACT US</p></a>
            </div>
            
        </div>
    </div>
</div>
<div *ngIf="category==='fruits' && CONFIG.title === 'KatbookStore'">
    <div class="min-cart-product-wrapper popup-content1 cle-menu1 avonHome1" style="width: auto !important;min-width: 1% !important;right: -8%!important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a href="javascript:;" class="nav-link menu-text" style="width: max-content;" href="#/about-us" ><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 700;color:#504d4d;text-transform: capitalize;">About Us</p></a>
                <a href="#/contact-us" class="nav-link menu-text" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 700;color:#504d4d;text-transform: capitalize;">Contact Us</p></a>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
    <!-- <div class="min-cart-product-wrapper popup-content1 cle-menu2 avonHome2" style="right: -4%;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a href="javascript:;" class="nav-link menu-text"href="#/about-us" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">ABOUT US</p></a>
                <a href="#/contact-us" class="nav-link menu-text" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">CONTACT US</p></a>
            </div>
            
        </div>
    
    </div> -->
    <!-- <div class="min-cart-product-wrapper popup-content1 cle-menu2 avonHome3" style="right: -4%;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a href="javascript:;" class="nav-link menu-text"href="#/about-us" style="width: max-content;" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">ABOUT US</p></a>
                <a href="#/contact-us" class="nav-link menu-text" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">CONTACT US</p></a>
            </div>
            
        </div>
    
    </div> -->
</div>
<!-- <div *ngIf="category==='plants' && CONFIG.title === 'LushNGreen'"> -->
    <div *ngIf="category==='plants' && CONFIG.title === 'LushNGreen'">
    <div class="min-cart-product-wrapper popup-content1 cle-menu1 avonHome1" style="right: 79%;top: 60% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a class="nav-link menu-text" style="width: max-content;cursor: pointer;" (click)="productsListSchift('Indoor Plants')" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d;cursor: pointer;">INDOOR PLANTS</p></a>
                <a (click)="productsListSchift('Outdoor Plants')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">OUTDOOR PLANTS</p></a>
                <a (click)="productsListSchift('Exotic Plants')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">EXOTIC PLANTS</p></a>
                <a (click)="productsListSchift('Miniatures')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">MINIATURES</p></a>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='fruits' && CONFIG.title === 'LushNGreen'">
    <div class="min-cart-product-wrapper popup-content1 cle-menu2 avonHome3" style="top: 75% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu" style="display: block;">
                <a href="javascript:;" class="nav-link menu-text"href="about-us" style="width: max-content;" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">ABOUT US</p></a>
                <a href="contact-us" class="nav-link menu-text" style="width: max-content;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">CONTACT US</p></a>
            </div>
            
        </div>
    </div>
</div>
<div *ngIf="category==='allcategories' && CONFIG.title === 'Taazah'">
<div class="min-cart-product-wrapper popup-content-Taz avonHome5">
    <div class="cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
        <div class="cle-categorymenu">
            <div *ngFor="let category of allCategories; let i = index;" class="cle-eachmenu">
                <h6 style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;cursor: pointer;margin-bottom: 20px;" class="txt" (click)="productListingNewTaz(category.name)">{{category.name}}</h6>
                
            </div>
        </div>
    </div>
</div>
</div>
<div *ngIf="category==='allcategories' && CONFIG.title === 'SriSenbaga'">
    <div class="min-cart-product-wrapper popup-content-Taz avonHome5" style="top: 100%; right: -10%;width: 140%;">
        <div class="cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="cle-categorymenu">
                <div *ngFor="let category of allCategories; let i = index;" class="cle-eachmenu">
                    <h6 style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;cursor: pointer;margin-bottom: 20px;" class="txt" (click)="productsListTazTest1(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
        </div>
    </div>
    </div>
<div *ngIf="category==='skincare' && CONFIG.title === 'Schift Cosmetics'">
    <div class="min-cart-product-wrapper popup-content1 cle-menu1 avonHome1" style="right: 79%;top: 60% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a class="nav-link menu-text" style="width: max-content;cursor: pointer;" (click)="productsListTazTest1('Cleanser')" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d;cursor: pointer;">Cleanser</p></a>
                <a (click)="productsListTazTest1('Moisturizers')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Moisturizers</p></a>
                <a (click)="productsListTazTest1('Mists and Toner')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Mists/Toner</p></a>
                <a (click)="productsListTazTest1('Masks')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Masks</p></a>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
    <div class="min-cart-product-wrapper popup-content1 cle-menu2 navBar2" style="right: 79%;top: 60% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a class="nav-link menu-text" style="width: max-content;cursor: pointer;" (click)="productsListTazTest1('Cleanser')" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d;cursor: pointer;">Cleanser</p></a>
                <a (click)="productsListTazTest1('Moisturizers')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Moisturizers</p></a>
                <a (click)="productsListTazTest1('Mists and Toner')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Mists/Toner</p></a>
                <a (click)="productsListTazTest1('Masks')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Masks</p></a>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
    <div class="min-cart-product-wrapper popup-content1 cle-menu2 avonHome3" style="right: 79%;top: 60% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <a class="nav-link menu-text" style="width: max-content;cursor: pointer;" (click)="productsListTazTest1('Cleanser')" ><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d;cursor: pointer;">Cleanser</p></a>
                <a (click)="productsListTazTest1('Moisturizers')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Moisturizers</p></a>
                <a (click)="productsListTazTest1('Mists and Toner')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Mists/Toner</p></a>
                <a (click)="productsListTazTest1('Masks')" class="nav-link menu-text" style="width: max-content;cursor: pointer;"><p style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">Masks</p></a>
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='vegetables' && CONFIG.title !== 'Schift Cosmetics'">
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5">
                <div *ngFor="let category of allCategories1; let i = index;">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6>
                </div>
            </div>
            <div class="col-7">
                <ul style="column-count:2;display: block;">


                    <li *ngFor="let category of allCategories1; let i = index;">

                        <img [title]=category.name [src]=category.category_icon [alt]=category.name style="height:100px;margin-bottom: 10px;cursor: pointer;width: 120px;" (click)="productListing(category.name)">
                    </li>
                </ul>
                <!-- <p class="close" *ngIf="appService.currentUrl !== 'checkout'" (click)="removeItem(i)">&#x292C;</p> -->
                <!-- <img [src]="categoryImage1" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName1)"> -->
                <!-- <img [src]="categoryImage2" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
                     <!-- <img src="assets/images/bestgifts/category2.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='others'">
   
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            
                <ul style="column-count:3">
                    <li *ngFor="let category of allCategories2; let i = index;" style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)"><p style="font-size: 13px;
                        font-weight: 500;">{{category.name}}</p></li>
                </ul>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='pages'">
   
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            
                <ul style="column-count:2">
                    <li  style="color: black!important;cursor: pointer;" class="txt">
                        <a href="#/about-us"><p style="font-size: 13px;
                            font-weight: 500;">ABOUT US</p></a>
                  </li>
                        <li  style="color: black!important;cursor: pointer;" class="txt">
                            <a href="#/contact-us"><p style="font-size: 13px;
                            font-weight: 500;">CONTACT US</p></a></li>
                            <li  style="color: black!important;cursor: pointer;" class="txt">
                                <a href="#/privacy-policy"><p style="font-size: 13px;
                                font-weight: 500;">PRIVACY POLICY</p></a></li>

<li  style="color: black!important;cursor: pointer;" class="txt">
    <a href="#/cancellation-policy"><p style="font-size: 13px;
    font-weight: 500;">CANCELLATION POLICY</p></a></li>
    <li  style="color: black!important;cursor: pointer;" class="txt">
        <a href="#/shipping-and-delivery"><p style="font-size: 13px;
        font-weight: 500;">SHIPPING AND DELIVERY</p></a></li>
        <li  style="color: black!important;cursor: pointer;" class="txt">
            <a href="#/terms-and-condition"><p style="font-size: 13px;
            font-weight: 500;">TERMS AND CONDITIONS</p></a></li>
                </ul>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='clepages'">
   
    <div class="min-cart-product-wrapper popup-content1" style="width: 0%!important;min-width: 32%!important">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            
                <ul>
                    <li  style="color: black!important;cursor: pointer;" class="txt">
                        <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="aboutUs()">ABOUT US</h6>
                  </li>
                        <li  style="color: black!important;cursor: pointer;" class="txt">
                            <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="contactUs()">CONTACT US</h6>
                            </li>
                          
                </ul>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>



<div *ngIf=" CONFIG.title == 'Deera'">
    <div  *ngIf="category == 'Baking' && subcategory4 "  class="min-cart-product-wrapper popup-content1" style="right: 79%;top: 60% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of subcategory4; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;    line-height: 1.6;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
            
            
        </div>
   
    </div>
    <div  *ngIf="category == 'Dining Disposable' && subcategory3 "  class="min-cart-product-wrapper popup-content1" style="right: 79%;top: 60% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of subcategory3; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6>
                    
                </div>
            </div>
            
            
        </div>
   
    </div>
    <div  *ngIf="category == 'Disposable Cutlery' && subcategory2"  class="min-cart-product-wrapper popup-content1" style="right: 55%;top: 60% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category1 of subcategory2; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;white-space: nowrap;line-height: 1.6;" class="txt" (click)="productListingDeera(category1.name,category)">{{category1.name}}</h6>
                </div>
            </div>
            
            
        </div>
   
    </div>
    <!-- <div  *ngIf="category == 'Packaging' && subcategory1"  class="min-cart-product-wrapper popup-content1" style="right: 25%;top: 60% !important;width: auto !important;min-width: 1% !important;">
        <div class="row cle-menurow" style="margin: 5px;">
            <div class="col-5 cle-categorymenu">
                <div *ngFor="let category of subcategory1; let i = index;" class="cle-eachmenu">
                    <h6 style="color: black!important;cursor: pointer;white-space: nowrap;    line-height: 1.6;" class="txt" (click)=" productListing (category.name)">{{category.name}}</h6>
                </div>
            </div>
            
            
        </div>
   
    </div> -->
    
    <!-- <div  *ngIf="category == 'Packaging' && subcategory1" (mouseover)="toggleDropdown(true)" (mouseout)="toggleDropdown(false)">
        <div class="dropdown" >
            <div class="dropdown-content" [class.show]="dropdownOpen">
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <div class="dropdown-submenu" (mouseover)="toggleSubmenu(true)" (mouseout)="toggleSubmenu(false)">
                <a href="#">Submenu</a>
                <div class="submenu-content" [class.show]="submenuOpen">
                  <a href="#">Submenu Link 1</a>
                  <a href="#">Submenu Link 2</a>
                  <div class="dropdown-submenu" (mouseover)="toggleSubSubmenu(true)" (mouseout)="toggleSubSubmenu(false)">
                    <a href="#">Sub-submenu</a>
                    <div class="subsubmenu-content" [class.show]="subSubmenuOpen">
                      <a href="#">Sub-submenu Link 1</a>
                      <a href="#">Sub-submenu Link 2</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
    </div> -->
</div>